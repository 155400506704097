.app_carta{
    display: flex;

    flex-direction: column;
   
    align-items: center;
    width: 100%;

    
    justify-items: center;
   
    
   
}
.app_carta img{
    width: 1000px;
    
    
}
@media screen and (max-width: 550px) {
   
  
    .app_carta img {
        width: 100%;
    }
     }
 @media screen and (max-width: 650px){
        .app_carta img {
            width: 80%;
            height: auto;
        }
        
    }
     