.app__video {
    height: 100vh;
    position: relative;
}
.app__video video {
width: 100%;
height: 100%;
object-fit: cover;
}
.app__video-overlay {
    position: absolute;
    inset: 0 ;
    background: rgba(0, 0, 0 , 0.65);
}
.app__video-overlay_circle {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 1px solid var(--color-golden);
    cursor: pointer;
}